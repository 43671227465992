var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Snackbar", { ref: "snackbar" }),
      _c("h3", { staticClass: "ma-2" }, [
        _vm._v("Настройки мерчанта - Recrypto "),
      ]),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap pa-0 ma-2", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "110", width: "400" },
            },
            [
              _vm._v(" URL платежного виджета "),
              _c("div", { staticClass: "mb-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "mb-4",
                    attrs: { href: _vm.geturl() + "/?a=2112" },
                  },
                  [_vm._v(" " + _vm._s(_vm.geturl()) + " ")]
                ),
              ]),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "100", width: "400" },
            },
            [
              _vm._v(" Документация по платежной странице: "),
              _c("br"),
              _c("div", { staticClass: "mb-4" }, [
                _c("a", { attrs: { href: "https://docs.noros.org/docs/" } }, [
                  _vm._v("https://docs.noros.org/docs/"),
                ]),
              ]),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "100", width: "400" },
            },
            [
              _vm._v(" Ваш api_key"),
              _c("br"),
              _c("div", { staticClass: "mb-4" }, [
                _vm._v(" " + _vm._s(_vm.token)),
              ]),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "100", width: "400" },
            },
            [
              _vm._v(" Адрес колбэк"),
              _c("br"),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "url",
                      outlined: "",
                      clearable: "",
                      "append-icon": "mdi-content-save",
                    },
                    on: {
                      "click:append": function ($event) {
                        return _vm.callbackSave()
                      },
                    },
                    model: {
                      value: _vm.callback,
                      callback: function ($$v) {
                        _vm.callback = $$v
                      },
                      expression: "callback",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "100", width: "400" },
            },
            [
              _vm._v(" Адрес для вывода"),
              _c("br"),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "wallet",
                      outlined: "",
                      clearable: "",
                      "append-icon": "mdi-content-save",
                    },
                    on: {
                      "click:append": function ($event) {
                        return _vm.walletSave()
                      },
                    },
                    model: {
                      value: _vm.wallet,
                      callback: function ($$v) {
                        _vm.wallet = $$v
                      },
                      expression: "wallet",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "pa-2 mb-2 mr-2",
              attrs: { height: "220", width: "400" },
            },
            [
              _vm._v(" Сменить пароль "),
              _c(
                "div",
                { staticClass: "mb-1" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Текущий пароль",
                      dense: "",
                      outlined: "",
                      clearable: "",
                      autocomplete: "new-password",
                      "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.show2 ? "text" : "password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.show2 = !_vm.show2
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Новый пароль",
                      dense: "",
                      outlined: "",
                      clearable: "",
                      "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.show1 ? "text" : "password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.show1 = !_vm.show1
                      },
                    },
                    model: {
                      value: _vm.newpassword,
                      callback: function ($$v) {
                        _vm.newpassword = $$v
                      },
                      expression: "newpassword",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blue",
                      attrs: { dense: "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.passSave()
                        },
                      },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }