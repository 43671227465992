<template>
  <div>

    <Snackbar ref="snackbar"></Snackbar>
    <h3 class=" ma-2 " >Настройки мерчанта - Recrypto </h3>


    <v-container fluid class="d-flex flex-wrap pa-0 ma-2 ">

      <v-card class="pa-2 mb-2 mr-2" height="110" width="400">
        URL платежного виджета
        <div class="mb-4"><a  class="mb-4" :href="geturl()+'/?a=2112'">  {{  geturl()}}  </a></div>
      </v-card>


      <v-card class="pa-2 mb-2 mr-2" height="100" width="400">
        Документация по платежной странице: <br>
        <div class="mb-4"><a href="https://docs.noros.org/docs/">https://docs.noros.org/docs/</a></div>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="100" width="400">
        Ваш api_key<br>
        <div class="mb-4"> {{ token }}</div>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="100" width="400">
        Адрес колбэк<br>
        <div class="mb-4">
          <v-text-field
              v-model="callback"
              label="url"

              outlined
              clearable

              append-icon="mdi-content-save"
              @click:append="callbackSave()"
          ></v-text-field>
          <!--v-btn class="blue" dense text @click="callbackSave()">Сохранить</v-btn-->
        </div>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="100" width="400">
        Адрес для вывода<br>
        <div class="mb-4">
          <v-text-field
              v-model="wallet"
              label="wallet"

              outlined
              clearable

              append-icon="mdi-content-save"
              @click:append="walletSave()"
          ></v-text-field>
          <!--v-btn class="blue" dense text @click="callbackSave()">Сохранить</v-btn-->
        </div>
      </v-card>

      <v-card class="pa-2 mb-2 mr-2" height="220" width="400">
        Сменить пароль
        <div class="mb-1">
          <v-text-field
              v-model="password"
              label="Текущий пароль"
              dense
              outlined
              clearable
              autocomplete="new-password"

              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"

          ></v-text-field>

          <v-text-field
              v-model="newpassword"
              label="Новый пароль"
              dense
              outlined
              clearable

              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"

          ></v-text-field>

          <v-btn class="blue" dense text @click="passSave()">Сохранить</v-btn>
        </div>
      </v-card>

    </v-container>










      <!--Ваш api_secret_key (токен выплат)<br>
      <div class="mb-4"> {{ payoutToken }}</div-->

      <!--Токен авторизации в телеграм боте<br>
      <div class="mb-4"> {{ tgtoken }}</div-->





      <!--Банки
      <div class="mb-4">
        <BankCheckgroup :selected="banks" v-on:change="bankschanged"></BankCheckgroup>
        <v-btn class="blue" dense text @click="bankSave()">Сохранить</v-btn>
      </div-->




  </div>
</template>
<script>

import BankCheckgroup from "../components/controls/BankCheckgroup.vue";
import {getHeaders} from "../store";

import Snackbar from "../components/controls/Snackbar.vue";
import {fetcher} from "../store";

let CryptoJS = require("crypto-js");

const RestApiUrl = process.env.VUE_APP_REST_API_URL_AUTH + "/changeprofile"

export default {
  name: 'UserConfig',

  // eslint-disable-next-line vue/no-unused-components
  components: {BankCheckgroup, Snackbar},

  data: () => ({

    show1:false,
    show2:false,

    users: [],
   // istest: testserver,
    password: "",
    newpassword: "",
   // callback2: null,
    banks2: [],
    wallet:null
  }),

  computed: {

    prodMode(){
      let n = !process.env.VUE_APP_TESTING
      return n
    },

    banks: {
      get: function () {
        return this.$store.state.user.banks
      },
      set: function (newValue) {
        console.debug(
            newValue
        )
      }
    },

    callback: {
      get: function () {
        return this.$store.state.user.callback
      },
      set: function (newValue) {
        this.$store.state.user.callback= newValue
        //this.callback2 = newValue
      }
    },



    token() {
      return this.$store.state.user.token
    },

    payoutToken() {
      return this.$store.state.user.payoutToken
    },


    tgtoken() {
      const sha1Hash = CryptoJS.SHA1(this.token);
      return sha1Hash.toString(CryptoJS.enc.Hex)
    },

    cryptokey() {

      return CryptoJS.AES.encrypt(
          this.token,
          CryptoJS.enc.Hex.parse("5a45304153674f5467414f577666323500000000000000000000000000000000"),
          {iv: CryptoJS.enc.Utf8.parse("")}
      ).toString()
          .replaceAll('+', '.')
          .replaceAll('/', '_',)
          .replaceAll('=', '-')
    }
    ,

  }
  ,

  methods: {

    geturl(){
      const s = `${process.env.VUE_APP_CALLBACK_URL}/${this.cryptokey}`
      return  s
    },

    bankschanged(val) {
      this.banks2 = val
    },

    /*
PATCH /api/auth/changeprofile

req.body.password, (string)
req.body.newpassword, (string)
req.body.callback, (string)
req.body.banks    массив ид выбранных банков строкой   пример: JSON.stringify([2,3,4])

поля отправляются по необходимости изменения
т.е. если меняется пароль то шлем
req.body.password, (string) (старый пароль)
req.body.newpassword, (string) (новый пароль)

если меняется адрес каллбэка то
req.body.callback, (string)
    */

    async callbackSave() {

      //this.$store.commit('authentication/setcallback', this.callback2)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({callback: this.callback,})
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async walletSave() {

      //this.$store.commit('authentication/setwallet', this.callback2)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({wallet: this.wallet,})
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    async bankSave() {
      this.$store.commit('authentication/setbanks', this.banks2)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({banks: this.banks2,})
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async passSave() {

      if (!this.newpassword){
        alert("не указан новый пароль")
        return
      }

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({password: this.password, newpassword: this.newpassword,})
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    test() {
      var CryptoJS = require("crypto-js");
      var encrypted = CryptoJS.AES.encrypt("00000000-0000-0000-0000-000000000000", "ZE0ASgOTgAOWvf25");
      console.log(encrypted.ciphertext.toString())
    }


  }
  ,

}
</script>
